import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import * as StatusIconComplete from '../../assets/images/cms/status_badge_bullet_complete.svg';
import * as StatusIconToDo from '../../assets/images/cms/status_badge_bullet_to_do.svg';
import * as StatusIconInProgress from '../../assets/images/cms/status_badge_bullet_in_progress.svg';
import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import collectionsStore from '@/store/modules/collectionsStore';
import {
  ContentCardData,
  ContentType,
  ActivityType,
  CourseStatus,
  MediaResourceType
} from '@/utilities/cmsUtilities';
import CmsCardBadge from '@/commoncomponents/CmsCardBadge/CmsCardBadge.vue';
import {getUserCollections} from '@/services/collections/api';

export const getStatusState = (stat: CourseStatus | undefined) => {
  switch (stat) {
    case CourseStatus.IN_PROGRESS:
      return {
        statusText: 'In progress',
        statusClass: 'status-in-progress',
        statusIcon: StatusIconInProgress
      };
    case CourseStatus.COMPLETE:
      return {
        statusText: 'Complete',
        statusClass: 'status-complete',
        statusIcon: StatusIconComplete
      };
    case CourseStatus.TO_DO:
      return {
        statusText: 'To do',
        statusClass: 'status-to-do',
        statusIcon: StatusIconToDo
      };
    default:
      return {
        statusText: undefined,
        statusClass: '',
        statusIcon: StatusIconToDo
      };
  }
};

@Component({
  name: 'cms-card',
  components: { CmsCardBadge },
  props: {
    cardData: {
      type: Object
    },
    fullWidth: {
      type: Boolean
    }
  }
})
export default class CmsCardComponent extends Vue {
  @Prop({
    type: Object
  })
  cardData!: ContentCardData;

  @Prop({
    type: Boolean
  })
  fullWidth!: boolean;

  @Prop({
    type: Boolean
  })
  showCollectionActions!: boolean;

  @Prop({
    type: Boolean
  })
  isHorizontalCard!: boolean;

  @Prop({
    type: Boolean
  })
  withRemoveIcon!: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  isContentSaved!: boolean;

  courseStatusText = '';

  statusBadgeClass = '';

  collectionChecked: boolean = false;

  statusBadgeIcon: string =
    '../../assets/images/cms/status_badge_bullet_in_progress.svg';

  showBadge = false;

  customContentCollectionsEnabled = false;

  async beforeMount() {
    await APP_UTILITIES.waitForLDClient();
    this.customContentCollectionsEnabled = await APP_UTILITIES.getFeatureFlag(
      APP_CONST.FEATURE_KEYS.customContentCollections
    );

    const { statusText, statusClass, statusIcon } = getStatusState(
      this.cardData.courseStatus
    );
    this.courseStatusText = statusText || '';

    if (this.customContentCollectionsEnabled && this.cardData.id) {
      this.collectionChecked = collectionsStore.isContentSelected(
        this.cardData.id
      );
    }

    this.statusBadgeClass = statusClass;
    this.statusBadgeIcon = statusIcon;
    this.cardData.imageUrl =
      this.cardData.imageUrl !== undefined
        ? this.cardData.imageUrl.includes('w=473')
          ? this.cardData.imageUrl
          : this.cardData.imageUrl + '?w=473'
        : undefined;
    this.showBadge = APP_CONST.CARD_CONTENT_TYPES_WITH_BADGES.includes(
      this.cardData.contentType || ''
    );
  }

  handleCollectionSavedClick(): void {
    this.$emit('collection-saved-click', this.cardData);
  }

  handleCollectionCheckboxClick() {
    if (!this.cardData.id) {
      return;
    }

    this.collectionChecked = !this.collectionChecked;
    if (this.collectionChecked) {
      collectionsStore.addSelectedContent(this.cardData.id);
    }
    else {
      collectionsStore.removeSelectedContent(this.cardData.id);
    }
  }

  handleClick() {
    // Navigate to the content landing page
    if (this.cardData.id) {
      //Navigate to content
      if (this.cardData.contentType === ContentType.COURSE) {
        this.$router.push({
          path: APP_CONST.APP_ROUTES.CMS_COURSE_LANDING.path,
          query: { id: this.cardData.id }
        });
      }
      else if (this.cardData.contentType === ContentType.ROUTINE) {
        this.$router.push({
          path: APP_CONST.APP_ROUTES.CMS_ROUTINE_LANDING.path,
          query: { id: this.cardData.id }
        });
      }
      else if (this.cardData.contentType === ContentType.STRATEGY) {
        this.$router.push({
          path: APP_CONST.APP_ROUTES.CMS_STRATEGY_LANDING.path,
          query: { id: this.cardData.id }
        });
      }
      else if (this.cardData.contentType === ContentType.RESOURCE) {
        const resourcePaths = {
          [MediaResourceType.MEDIA]:
            APP_CONST.APP_ROUTES.CMS_MEDIA_RESOURCE_LANDING.path,
          [MediaResourceType.MEDIA_VIDEO]:
            APP_CONST.APP_ROUTES.CMS_VIDEO_RESOURCE_LANDING.path,
          [MediaResourceType.MEDIA_VIDEO_CAPITALIZED]:
            APP_CONST.APP_ROUTES.CMS_VIDEO_RESOURCE_LANDING.path,
          [MediaResourceType.MEDIA_LONG]:
            APP_CONST.APP_ROUTES.CMS_MEDIA_RESOURCE_LONG_LANDING.path
        };

        const resourcePath =
          resourcePaths[this.cardData.resourceType as MediaResourceType];
        if (resourcePath) {
          this.$router.push({
            path: resourcePath,
            query: { id: this.cardData.id }
          });
        }
        else {
          console.error(
            'The resource type is not defined, add the new path for this new resource type.'
          );
        }
      }
      else if (this.cardData.contentType === ContentType.ACTIVITY) {
        switch (this.cardData.activityType) {
          case ActivityType.FITNESS:
            this.$router.push({
              path: APP_CONST.APP_ROUTES.CMS_ACTIVITY_FITNESS_LANDING.path,
              query: {
                id: this.cardData.id,
                activityType: this.cardData.activityType
              }
            });
            break;
          default:
            this.$router.push({
              path: APP_CONST.APP_ROUTES.CMS_ACTIVITY_LANDING.path,
              query: {
                id: this.cardData.id,
                activityType: this.cardData.activityType
              }
            });
            break;
        }
      }
      else if (this.cardData.contentType === ContentType.LEARN_ABOUT) {
        this.$router.push({
          path: APP_CONST.APP_ROUTES.CMS_LEARN_ABOUT_LANDING.path,
          query: { id: this.cardData.id }
        });
      }
      else if (this.cardData.contentType === ContentType.CONTENT_PREVIEW) {
        this.$router.push({
          path: APP_CONST.APP_ROUTES.CMS_CONTENT_PREVIEW_LANDING.path,
          query: { id: this.cardData.id }
        });
      }
    }
    //ToDo: Build out others once their landing pages are finished.
  }

  handleDelete(e: Event): void {
    e.stopPropagation();
    this.$emit('delete', this.cardData);
  }

  private get selectedContentCount() {
    return collectionsStore.selectedContentCount;
  }

  /* Clear the checkbox after the selection has been cleared. Normally after a successful content save in the flyout */
  @Watch('selectedContentCount', { immediate: true })
  onSelectedContentCountChanged(newValue: number) {
    if (newValue === 0) {
      this.collectionChecked = false;
    }
  }
}
